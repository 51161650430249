body {
  background: #FBFBFE
}

a {
  color: #21AA73;
}

a:hover {
  color: #156d4a;
}

.main-sidebar {
  box-shadow: none;
  border-right: 1px solid #EEEFF1;
  z-index: 1040 !important;
}

.main-navbar {
  box-shadow: none !important;
  border-bottom: 1px solid #EEEFF1;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .page-header, .page-subtitle {
  color: #3D3C3E;
}

.text-fiord-blue {
  color: #3D3C3E !important;
}

.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover, .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #22B177;
  background-color: #F6F9FE;
  color: #22B177;
}

.main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item .nav-link:hover i, .main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item:hover i {
  color: #22B177;
}

.card, .card-small {
  box-shadow: none;
  border-radius: 0px;
  border: 1px solid #EEEFF1;
}

.card-post__image {
  border-radius: 0px;
}

.subnav-item {
  background-color: #EEEEEE;
}

.react-flags-select-bootstrap {
  background-color: #FFFFFF;
  padding-bottom: 0px !important;
}

.header-subtext {
  margin: 0px;
  margin-top: 8px;
  color: #BCBDC0;
  font-size: 12px;
}

.menu-flags-button {
  border: 1px solid #e1e5eb !important;
}

.btn-accent {
  background-color: #21AA73 !important;
  border-color: #21AA73 !important;
}

.btn-accent, .btn-accent.disabled, .btn-accent:hover {
  background-color: #21AA73;
  border-color: #21AA73;
}

.btn-outline-accent, .btn-outline-accent:hover {
  background-color: transparent;
  background-image: none;
  border-color: #21AA73;
  color: #21AA73;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #21AA73;
  border-color: #21AA73;
}

.btn-primary, .btn-outline-accent:active {
  border-color: #21AA73 !important;
  background-color: #21AA73 !important;
}

.btn-primary:hover {
  background-color: #21AA73;
  border-color: #21AA73;
}

.table {
  border: none !important;
}

.table td, .table th {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 12px;
  color: #5A5B5F;
}

.table thead th {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  color: '#A3A5A9'
}

.form-label-custom {
  font-size: 11px;
  text-transform: uppercase;
}

.dropdown-menu-right {
  right: -69px !important;
}

.modal-backdrop.show {
  opacity: 0.5 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  padding-left: 40px;
}

.modal-footer {
  display: block !important;
  padding: auto;
}

.custom-control-description {
  font-size: 11px;
  text-transform: uppercase;
}

.page-link {
  font-size: 12px;
  color: #5A5B5F;
}

.react-datepicker__input-container input {
  padding-left: 0.75rem;
}

.btn-download-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
